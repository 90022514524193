import React from "react";

import {  Card, Container, Spacer } from "react-neu";
import styled from "styled-components";
import StartPage from "components/StartPage";
import StartSplit from "components/StartSplit";
// const StyledContainerPresentation = styled(Container)`
//     color: rgb(152, 251, 152);
//     font-size: 25px;
//     opacity: 0.66;
//     font-weight: 400;
//     text-align: center;
//     @media (max-width: 600px) {
//         display: none;
//         justify-content: center;
//         font-size: 14px;
//         font-weight: 100;
//         width: auto;
//     }
// `;
const Faq: React.FC = () => {
    return (
        <StartPage>
            <StyledTitle>FAQ : BM Conduite Auto-école Marseille </StyledTitle>
            <StyledHeaderOne> L'auto-école à Marseille, pour obtenir votre votre Code de la Route et votre permis rapidement.</StyledHeaderOne>

            <StyledHeaderTwo>
                BM conduite Marseille répond à vos questions.
            </StyledHeaderTwo>
            {/* 
            <StyledContainerPresentation>
                <Card>
                    <StyledTextPrimary>Obtenez votre Code de la Route et votre permis rapidement !</StyledTextPrimary>

                    <Box>
                        <StyledText>Nous pensons à vos économies : </StyledText>
                        <Separator />
                        <Split>
                            <Box>
                                <StyledText> D'argent </StyledText>
                                <StyledText> Les meilleurs prix pour votre code de la route et votre permis </StyledText>
                            </Box>

                            <Box>
                                <StyledText> De temps </StyledText>
                                <StyledText> Trouver les meilleurs disponibilités pour atteindre vos objectifs au plus vite</StyledText>
                            </Box>
                        </Split>
                  
                        <Separator />

                        <StyledText> Tout cela en étant bienveillant envers l'écologique !</StyledText>
                    </Box>
                </Card>
            </StyledContainerPresentation> */}

            <Spacer />

            <Container size="lg">
                <Container>
                    <StartSplit>
                        <StyledCardDescription>
                            <StyledCardName> Ou se situe BM Conduite </StyledCardName>
                            <StyledText> 92 Rue Jean de Bernardy, 13001 Marseille, France </StyledText>

                            <StyledCardName> Comment obtenir son permis de conduire rapidement</StyledCardName>
                            <StyledText> Auto-école Marseille BM Conduite vous accueil pour obtenir votre Permis de conduire rapidement.</StyledText>

                            <StyledCardName> Ou refaire son permis de conduire</StyledCardName>
                            <StyledText> BM Conduite vous invitent dans ses locaux pour refaire votre permis de conduire et code de la route très rapidement.</StyledText>

                            <StyledCardName> Obtenir son code de la route rapidement </StyledCardName>
                            <StyledText> Venez suivre les précieux conseils de nos accompagnateurs et leurs expériences.</StyledText>

                            <StyledCardName>Comment avoir une date d'examen permis rapidement</StyledCardName>
                            <StyledText> BM Conduite mets à votre disposition leurs services afin d'obtenir une date d'examen de Permis rapidement.</StyledText>

                            <StyledCardName> Permis accéléré Marseille prix </StyledCardName>
                            <StyledText> Auto-école Marseille BM Conduite vous accompagnent pour votre permis de conduire, et code de la route en accéléré à 850€. Ceci avec les précieux conseils de nos accompagnateurs et leurs expériences.</StyledText>
            
                            <StyledCardName> Permis de conduire accéléré </StyledCardName>
                            <StyledText> Auto-école Marseille BM Conduite vous accompagnent pour votre permis et code rapidement avec les dates les plus proches.</StyledText>

                            <StyledCardName> Auto-école marseille pas chère </StyledCardName>
                            <StyledText> BM Conduite vous forment pour 37€ l'heure de conduite.</StyledText>
                            <StyledText> Le permis de conduire à 699€, avec 20 heures de conduite, en vous offrant une date très rapide.</StyledText>

                            <StyledCardName> Auto-école marseille CPF </StyledCardName>
                            <StyledText>
                                Inscrivez-vous chez Auto-école Marseille BM Conduite pour passer votre code de la route et votre permis de conduite à l'aide du CPF.
                            </StyledText>

                            <StyledCardName> Auto-école marseille 13001 </StyledCardName>
                            <StyledText>
                                Auto-école Marseille BM Conduite vous accueil dans ses locaux pour le Code de la route, et pour vos heures de conduites accompagnées des meilleurs
                                moniteurs.
                            </StyledText>

                            <StyledCardName> Prix du permis de conduite marseille </StyledCardName>
                            <StyledText> Votre permis de conduire à 699€ chez Auto-école Marseille BM Conduite.</StyledText>
                        </StyledCardDescription>
                    </StartSplit>
                </Container>
            </Container>
            <Spacer size="lg"></Spacer>

            {/* <Box row justifyContent="center">
                <Button text="Tarifs" variant="tertiary" href="/"></Button>
            </Box>

            <Box row justifyContent="center">
                <Button text="Contact" variant="tertiary" href="/contact"></Button>
            </Box> */}
        </StartPage>
    );
};
// color: ${props => props.theme.textColor};

const StyledTitle = styled.title`
    color: rgb(152, 251, 152);
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    @media (max-width: 400px) {
        justify-content: center;
        font-size: 14px;
        font-weight: 100;
        width: auto;
    }
`;

const StyledHeaderOne = styled.h1`
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    @media (max-width: 600px) {
        justify-content: center;
        font-size: 16 px;
        font-weight: 100;
        width: auto;
    }
`;

const StyledHeaderTwo = styled.h2`
    font-size: 17px;
    text-align: center;
    @media (max-width: 600px) {
        justify-content: center;
        font-size: 15px;
        width: auto;
    }
`;

const StyledText = styled.p`
    color: ${props => props.theme.textColor};
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    @media (max-width: 400px) {
        justify-content: center;
        font-size: 14px;
        font-weight: 100;
        width: auto;
    }
`;

// const StyledTextPrimary = styled.p`
//     font-size: 18px;
//     opacity: 0.66;
//     font-weight: 400;
//     text-align: center;
//     @media (max-width: 400px) {
//         justify-content: center;
//         font-size: 14px;
//         font-weight: 100;
//         width: auto;
//     }
// `;

// const StyledTitle = styled.div`
//     font-size: 24px;
//     font-weight: 700;
//     text-align: center;
//     @media (max-width: 400px) {
//         justify-content: center;
//         font-size: 14px;
//         font-weight: 100;
//         width: auto;
//     }
// `;

// const StyledList = styled.li`
//     color: ${props => props.theme.colors.grey[400]};
//     font-size: 16px;
//     min-height: 56px;
//     text-align: center;
// `;

// const StyledCardMap = styled.

export const StyledCardDescription = styled(Card)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: 44px;
    min-width: 44px;
    padding: 0;
    width: 300px;
    text-decoration: none;
    @media (max-width: 400px) {
        justify-content: center;
        width: auto;
    }
`;

export const StyledCardMap = styled(Card)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: 44px;
    min-width: 44px;
    padding: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    @media (max-width: 400px) {
        justify-content: center;
        width: 100%;
        height: 100%;
        width: auto;
    }
`;

const StyledCardName = styled.div`
    color: rgb(152, 251, 152);

    font-size: 24px;
    font-weight: 700;
    text-align: center;
    @media (max-width: 400px) {
        justify-content: center;
        font-size: 14px;
        font-weight: 100;
        width: auto;
    }
`;

// const StyledSectionTitle = styled.div`
//     color: ${props => props.theme.colors.primary.main};
//     color: 	rgb(152,251,152);

//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;

// const StyledSectionTitle = styled.div`
//     color: rgb(152, 251, 152);
//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;
// const StyledTitleSecondary = styled.div`
//     color: ${props => props.theme.colors.primary.secondary};
//     font-size: 16px;
//     font-weight: 600;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;

export default Faq;
