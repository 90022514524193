import React from "react";

import { Box, Card, CardContent, Container, Separator, Spacer } from "react-neu";
import styled from "styled-components";
import StartPage from "components/StartPage";
import StartSplit from "components/StartSplit";
import Split from "components/Split";
// import { StyledActionButton, StyledTelRef } from "components/ActionButton";
import { StyledTelRef } from "components/ActionButton";

// const StyledContainerPresentation = styled(Container)`
//     color: rgb(152, 251, 152);
//     font-size: 25px;
//     opacity: 0.66;
//     font-weight: 400;
//     text-align: center;
//     @media (max-width: 600px) {
//         display: none;
//         justify-content: center;
//         font-size: 14px;
//         font-weight: 100;
//         width: auto;
//     }
// `;
const Contact: React.FC = () => {
    return (
        <StartPage>
            <StyledTitle>Page de contact : BM Conduite Auto-école Marseille </StyledTitle>

            <StyledHeaderOne>L'auto-école à Marseille 13001, pour obtenir votre votre Code de la Route et votre permis rapidement.</StyledHeaderOne>

            {/* 
            <StyledContainerPresentation>
                <Card>
                    <StyledTextPrimary>Obtenez votre Code de la Route et votre permis rapidement !</StyledTextPrimary>

                    <Box>
                        <StyledText>Nous pensons à vos économies : </StyledText>
                        <Separator />
                        <Split>
                            <Box>
                                <StyledText> D'argent </StyledText>
                                <StyledText> Les meilleurs prix pour votre code de la route et votre permis </StyledText>
                            </Box>

                            <Box>
                                <StyledText> De temps </StyledText>
                                <StyledText> Trouver les meilleurs disponibilités pour atteindre vos objectifs au plus vite</StyledText>
                            </Box>
                        </Split>
                  
                        <Separator />

                        <StyledText> Tout cela en étant bienveillant envers l'écologique !</StyledText>
                    </Box>
                </Card>
            </StyledContainerPresentation> */}

            <Spacer />

            <Container size="lg">
                    <StartSplit>
                        <StyledCardDescription>
                            <StyledCardName> Nous contacter </StyledCardName>

                            <StyledText>
                                <StyledTelRef href="tel:0954165907"> ☎ - 09.54.16.59.07 </StyledTelRef>
                            </StyledText>

                            <StyledText>
                                {/* <StyledActionButton> */}
                                <StyledTelRef href="tel:0778008275"> 📱 - 07.78.00.82.75 </StyledTelRef>
                                {/* </StyledActionButton> */}
                            </StyledText>

                            <StyledText> 👻 - bmconduite</StyledText>
                            <StyledText> ✉ - b.mconduite@yahoo.com</StyledText>

                            <Separator></Separator>

                            <StyledCardName>Addresse : </StyledCardName>
                            <StyledText>92 Rue Jean de Bernardy</StyledText>
                            <StyledText>13001 Marseille, France</StyledText>
                            <Spacer></Spacer>
                            <StyledCardName> Moyens d'accès</StyledCardName>

                            <CardContent>
                                <Box>
                                    <Split>
                                        <StyledText> TRAM: </StyledText>
                                        <StyledText> T2 arrêt Longchamp </StyledText>
                                    </Split>
                                </Box>

                                <Separator></Separator>

                                <Box>
                                    <StyledTitle> BUS: </StyledTitle>
                                    <Box>
                                        <StyledText> 49, 72 , 81, L050 , L051 </StyledText>
                                    </Box>
                                </Box>
                                <Separator></Separator>

                                <Box>
                                    <StyledTitle> METRO: </StyledTitle>
                                    <StyledText> M1 </StyledText>
                                </Box>
                            </CardContent>
                        </StyledCardDescription>

                        <Box justifyContent="center">
                            <StyledCardMap>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2903.5119157518843!2d5.389327150543104!3d43.30354208288671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9c09e7c4dd647%3A0x78cba775502ac00a!2s92%20Rue%20Jean%20de%20Bernardy%2C%2013001%20Marseille%2C%20France!5e0!3m2!1sen!2snl!4v1643570955781!5m2!1sen!2snl"
                                    width="600"
                                    height="450"
                                    title="Nous retrouver et commencer son Code de la Route et Permis de conduire, à vous la mobilité ??"
                                    // style="border:0;"
                                    style={{ border: "0" }}
                                    allowFullScreen={true}
                                    loading="lazy"
                                ></iframe>

                                <StyledHeaderTwo>
                                    BM conduite Marseille est une auto école avec des moniteurs et monitrices passionnés danls le 13001.
                                    <p>
                                        Chacun avec plus de <b>10 ans d'expériences </b> dans <i>l'apprentissage à la conduite.</i>{" "}
                                    </p>
                                    Votre Code de la Route et votre Permis de Conduire, avec les meilleurs formateurs, dans 4 voitures confortable. et ce au meilleur prix.
                                </StyledHeaderTwo>
                            </StyledCardMap>
                        </Box>
                    </StartSplit>
            </Container>
            <Spacer size="lg"></Spacer>

            {/* <Box row justifyContent="center">
                <Button text="Tarifs" variant="tertiary" href="/"></Button>
            </Box>

            <Box row justifyContent="center">
                <Button text="Contact" variant="tertiary" href="/contact"></Button>
            </Box> */}
        </StartPage>
    );
};
// color: ${props => props.theme.textColor};

const StyledTitle = styled.title`
    color: rgb(152, 251, 152);
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    @media (max-width: 400px) {
        justify-content: center;
        font-size: 14px;
        font-weight: 100;
        width: auto;
    }
`;

const StyledHeaderOne = styled.h1`
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    @media (max-width: 600px) {
        justify-content: center;
        font-size: 16 px;
        font-weight: 100;
        width: auto;
    }
`;

const StyledHeaderTwo = styled.h2`
    font-size: 17px;
    text-align: center;
    @media (max-width: 600px) {
        justify-content: center;
        font-size: 15px;
        width: auto;
    }
`;

const StyledText = styled.p`
    color: ${props => props.theme.textColor};
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    @media (max-width: 400px) {
        justify-content: center;
        font-size: 14px;
        font-weight: 100;
        width: auto;
    }
`;

// const StyledTextPrimary = styled.p`
//     font-size: 18px;
//     opacity: 0.66;
//     font-weight: 400;
//     text-align: center;
//     @media (max-width: 400px) {
//         justify-content: center;
//         font-size: 14px;
//         font-weight: 100;
//         width: auto;
//     }
// `;

// const StyledTitle = styled.div`
//     font-size: 24px;
//     font-weight: 700;
//     text-align: center;
//     @media (max-width: 400px) {
//         justify-content: center;
//         font-size: 14px;
//         font-weight: 100;
//         width: auto;
//     }
// `;

// const StyledList = styled.li`
//     color: ${props => props.theme.colors.grey[400]};
//     font-size: 16px;
//     min-height: 56px;
//     text-align: center;
// `;

// const StyledCardMap = styled.

export const StyledCardDescription = styled(Card)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: 44px;
    min-width: 44px;
    padding: 0;
    width: 550px;

    text-decoration: none;
    @media (max-width: 650px) {
        justify-content: center;
        width: auto;
        width: 300px;
    }
`;

export const StyledCardMap = styled(Card)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: 44px;
    min-width: 44px;
    padding: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    @media (max-width: 400px) {
        justify-content: center;
        width: 100%;
        height: 100%;
        width: auto;
    }
`;

const StyledCardName = styled.div`
    color: rgb(152, 251, 152);

    font-size: 24px;
    font-weight: 700;
    text-align: center;
    @media (max-width: 400px) {
        justify-content: center;
        font-size: 14px;
        font-weight: 100;
        width: auto;
    }
`;

// const StyledSectionTitle = styled.div`
//     color: ${props => props.theme.colors.primary.main};
//     color: 	rgb(152,251,152);

//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;

// const StyledSectionTitle = styled.div`
//     color: rgb(152, 251, 152);
//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;
// const StyledTitleSecondary = styled.div`
//     color: ${props => props.theme.colors.primary.secondary};
//     font-size: 16px;
//     font-weight: 600;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;

export default Contact;
