import React from "react";

import { Container, Spacer } from "react-neu";
import styled from "styled-components";

import Logo from "components/Logo";
// import Logo from "components/IconLogo";
// import DarkModeSwitch from "../DarkModeSwitch";
import { Link } from "react-router-dom";
// import fruitsBack from "assets/galery/grocery_full.jpg";
// import fruitsBack from "/grocery.jpg";
// import fruitsBack from "assets/galery/grocery.jpg";
// import fruitsBack from "assets/galery/fruit.png";

// import SideDrawer from "./components/SideDrawer";
import DrawerToggleButton from "./components/DrawerToggleButton";
import Nav from "./components/Nav";
// import DrawerToggleButton from "./components/DrawerToggleButton";

interface TopBarProps {}

const TopBar: React.FC<TopBarProps> = () => {
    const [isOpenDrawer, setOpenDrawer] = React.useState(false);
    // const [isMobile, setIsMobile] = React.useState(false);

    let isMobile = true;
    if (typeof window != "undefined") {
        // if (window.innerWidth > 650) {
        //     isMobile = false;
        // }
    }

    const handleClickOpen = () => {
        setOpenDrawer(!isOpenDrawer);
    };

    // let sideDrawer;

    // if (isMobile && isOpenDrawer) {
    //     sideDrawer = <SideDrawer handleClick={handleClickOpen} />;
    // }
    return (
        <StyledTopBar>
            <Container size="lg">
                <StyledTopBarInner>
                    <StyledLogoWrapper>
                        <Logo />
                    </StyledLogoWrapper>

                    {/* <button onClick={handleClickOpen}> Open</button> */}
                    {/* {sideDrawer} */}

                    {/* <StyledMobileMenuWrapper>
                        <Nav onDismiss={handleClickOpen} mobileMenu={isMobile} />
                    </StyledMobileMenuWrapper> */}

                    {/* {location.pathname !== "/" && (
            <>
              {mobileMenu ? (
                <StyledMobileMenuWrapper>
                  <Nav
                    onDismiss={handleDismissMobileMenu}
                    mobileMenu={mobileMenu}
                  />
                </StyledMobileMenuWrapper>
              ) : (
                <StyledNavWrapper>
                  <Nav
                    onDismiss={handleDismissMobileMenu}
                    mobileMenu={mobileMenu}
                  />
                </StyledNavWrapper>
              )}
            </>
          )} */}

                    <StyledLeftMenuBalancesWrapper>
                        <StyledAccountButtonWrapper>
                            <Container>
                                <DrawerToggleButton handleClick={handleClickOpen} />
                                <Nav onDismiss={handleClickOpen} mobileMenu={isMobile} isOpen={isOpenDrawer}></Nav>
                            </Container>

                            {/*                                 
                            {isMobile ? (
                                <Container>
                                    <DrawerToggleButton handleClick={handleClickOpen} />
                                    <Nav onDismiss={handleClickOpen} mobileMenu={isMobile} isOpen={isOpenDrawer}></Nav>
                                </Container>
                            ) : (
                                <Container>
                                    <StyledCardName> ☎ +33 9 52 55 20 68 </StyledCardName>
                                    <StyledCardName> 👻 3mmarket</StyledCardName>
                                    <StyledCardName>
                                        <a
                                            href="https://deliveroo.fr/en/menu/aix-en-provence/avenue-de-leurope/market-avenue-perouse?utm_medium=affiliate&utm_source=google_maps_link"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                width={"80px"}
                                                height={"45px"}
                                                src="/deliveroo_circle.png"
                                                alt="3M Market livre via Uber Eats. Choissisez et faites-vous livrer tout vos besoins et envies du quotidien, avec des produits US/Japan"
                                            ></img>
                                        </a>
                                    </StyledCardName>

                                    <StyledCardName>
                                        <a
                                            href="https://www.ubereats.com/fr/store/3m-alimentation-market/cDhW-1fXTXqoqGUXkbnaBA?utm_source=google&utm_medium=organic&utm_campaign=place-action-link"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                width={"85px"}
                                                height={"45px"}
                                                src="/uber_eats_logo.jpg"
                                                alt="3M Market livre via Uber Eats. Choissisez et faites-vous livrer tout vos besoins et envies du quotidien, avec des produits US/Japan"
                                                style={{ borderRadius: "10px" }}
                                            ></img>
                                        </a>
                                    </StyledCardName>
                                </Container>
                            )} */}

                            {/*
             <StyledLangSwitch>
                <LanguageSwitcher/>
              </StyledLangSwitch>
              <Spacer />*/}

                            {/* <Box>
                                <StyledNavLinkDiv>
                                    <StyledLink to="/">Accueil</StyledLink>
                                </StyledNavLinkDiv>
                            </Box>
                            <Box>
                                <StyledNavLinkDiv>
                                    <StyledLink to="/contact">Contact</StyledLink>
                                </StyledNavLinkDiv>

                                <StyledNavLinkDiv>
                                    <StyledLink to="/services">Formations</StyledLink>
                                </StyledNavLinkDiv>
                            </Box> */}

                            <Spacer />
                            {/* {location.pathname !== "/" ? (
                <WalletButton />
              ) : (
                <Button size="sm" text="Open App" to="/" />
              )} */}
                        </StyledAccountButtonWrapper>
                        {/* {location.pathname !== "/" && (
                            <>
                                <Spacer />
                                <StyledMenuButton onClick={handlePresentMobileMenu}>
                                    <MenuIcon />
                                </StyledMenuButton>
                            </>
                        )} */}
                    </StyledLeftMenuBalancesWrapper>
                </StyledTopBarInner>
            </Container>
        </StyledTopBar>
    );
};

// const StyledCardName = styled.div`
//     color: ${props => props.theme.textColor};
//     font-size: 24px;
//     font-weight: 700;
//     width: 100%;
//     text-align: center;
// `;

// const Button = styled.button`
//     vertical-align: middle;
//     display: inline-block;
//     padding: 1.3em 1.5em 1.2em;
//     box-sizing: border-box;
//     border: 1px solid #d9252c;
//     background: #d9252c;
//     color: #fff;
//     border-radius: 0.33em;
//     font-size: 1.5rem;
//     line-height: 1.2em;
//     font-weight: 500;
//     text-decoration: none;
//     text-transform: uppercase;
//     text-indent: 0.12em;
//     letter-spacing: 0.12em;
// `;

export const StyledLink = styled(Link)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 1px;
    min-height: 17px;
    min-width: 44px;
    padding: 0;
    color: #ff0800;
    @media (max-width: 650px) {
        justify-content: center;
    }
`;

const StyledLogoWrapper = styled.div`
    width: 250px;
    @media (max-width: 550px) {
        width: auto;
    }
`;

const StyledTopBar = styled.div`
    align-items: center;
    display: flex;
    width: 100%;
    background-color: #333;
    height: 90px;
    justify-content: flex-end;
    @media (max-width: 650px) {
        justify-content: center;
        width: 100%;
        height: 90px;
        position: fixed;
        top: 0;
    }
`;
// background-image: url(${fruitsBack});

const StyledTopBarInner = styled.div`
    align-items: center;
    display: flex;
    height: 72px;
    justify-content: space-between;
    max-width: ${props => props.theme.siteWidth}px;
    width: 100%;
`;

// const StyledNavWrapper = styled.div`
//   display: flex;
//   flex: 1;
//   justify-content: center;
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

// const StyledMobileMenuWrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     position: fixed;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     z-index: 1000;
// `;

const StyledAccountButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 650px) {
        justify-content: center;
        width: auto;
    }
`;

const StyledLeftMenuBalancesWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 72px;
    justify-content: space-between;
`;
// const StyledTopBarDarkModeSwitch = styled.div`
//     @media (max-width: 1130px) {
//     }
// `;

// const StyledContainerLinks = styled(Container)`
//     align-items: center;
//     display: row;
//     width: 100%;
//     justify-content: flex-end;
//     @media (max-width: 650px) {
//         justify-content: center;
//     }
// `;
// const StyledTopBarDarkModeSwitch = styled.div`
//     @media (max-width: 1130px) {
//         display: none;
//     }
// `;
// const StyledLangSwitch = styled.div`
//   @media (max-width: 1130px) {
//     display: none;
//   }
// `;
export default TopBar;
