import React from "react";
import styled from "styled-components";
import { Box, Container, Spacer, useTheme } from "react-neu";
import { NavLink } from "react-router-dom";
import Split from "components/Split";
// import footers from "constants/Footers.json";
// import DarkModeSwitch from "../DarkModeSwitch";
import { StyledCardMap } from "views/Contact/Contact";
import { StyledTelRef, StyledActionButton } from "components/ActionButton";
import Logo from "components/Logo";
import { QualiopiLogo } from "components/Logo/QualiopiLogo";
const StartFooter: React.FC = () => {
    // const footerItems = footers.filter(footer => footer.homeDisplay === true);
    const { darkMode } = useTheme();
    return (
        <StyledFooter darkMode={darkMode}>
            <Container>
                {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2903.5119157518843!2d5.389327150543104!3d43.30354208288671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9c09e7c4dd647%3A0x78cba775502ac00a!2s92%20Rue%20Jean%20de%20Bernardy%2C%2013001%20Marseille%2C%20France!5e0!3m2!1sen!2snl!4v1643570955781!5m2!1sen!2snl"
                    width="600"
                    height="450"
                    // style="border:0;"
                    style={{ border: "0" }}
                    allowFullScreen={true}
                    loading="lazy"
                ></iframe> */}

                <p>Toutes nos formations sont éligibles au CPF.</p>
                <QualiopiLogo></QualiopiLogo>
                <Split>
                    {/* <Box justifyContent="center"> */}
                    {/* <Container> */}
                    {/* <Spacer></Spacer> */}
                    <StyledCardMap>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2903.5119157518843!2d5.389327150543104!3d43.30354208288671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9c09e7c4dd647%3A0x78cba775502ac00a!2s92%20Rue%20Jean%20de%20Bernardy%2C%2013001%20Marseille%2C%20France!5e0!3m2!1sen!2snl!4v1643570955781!5m2!1sen!2snl"
                            width="100%"
                            height="100%"
                            title="Nous retrouver et commencer son Code de la Route et Permis de conduire, à vous la mobilité ?"
                            // style="border:0;"
                            style={{ border: "0" }}
                            // allowFullScreen={true}
                            loading="lazy"
                        ></iframe>
                    </StyledCardMap>
                    {/* </Container> */}
                    {/* </Box> */}

                    <Container>
                        <StyledActionButton>
                            <StyledTelRef href="tel:0954165907"> ☎ +33 9.54.16.59.07 </StyledTelRef>
                        </StyledActionButton>

                        <StyledActionButton>
                            <StyledTelRef href="tel:0778008275"> ☎- +33 7 78 00 82 75 </StyledTelRef>
                        </StyledActionButton>

                        <StyledActionButton> 👻 - bmconduite</StyledActionButton>

                        <StyledCardName>92 Rue Jean de Bernardy, </StyledCardName>
                        <StyledCardName> 13001 Marseille, France</StyledCardName>
                    </Container>

                    {/* <img src="/deliveroo_circle.png">
                        <a
                            href="https://deliveroo.fr/en/menu/aix-en-provence/avenue-de-leurope/market-avenue-perouse?utm_medium=affiliate&utm_source=google_maps_link"
                            target="_blank"
                        >
                            Deliveroo
                        </a>
                    </img> */}
                </Split>

                <Spacer size="md" />
            </Container>
        </StyledFooter>
    );
};

interface StyledFooterProps {
    darkMode?: boolean;
}
const StyledFooter = styled.div<StyledFooterProps>`
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: ${props => (props.darkMode ? props.theme.colors.grey[800] : props.theme.colors.grey[300])};
`;

export const StyledLink = styled.a`
    color: ${props => props.theme.colors.grey[500]};
    padding-left: ${props => props.theme.spacing[3]}px;
    padding-right: ${props => props.theme.spacing[3]}px;
    text-decoration: none;
    &:hover {
        color: ${props => props.theme.colors.grey[600]};
    }
`;

export const StyledRouterLink = styled(NavLink)`
    color: ${props => props.theme.colors.grey[500]};
    padding-left: ${props => props.theme.spacing[3]}px;
    padding-right: ${props => props.theme.spacing[3]}px;
    text-decoration: none;
    &:hover {
        color: ${props => props.theme.colors.grey[600]};
    }
`;

const StyledCardName = styled.div`
    color: ${props => props.theme.textColor};
    font-size: 24px;
    font-weight: 700;
    width: 100%;
    text-align: center;
`;

// const StyledSectionTitle = styled.div`
//     color: ${props => props.theme.colors.primary.main};
//     color: 	rgb(152,251,152);

//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;
// const StyledSectionTitle = styled.div`
//     color: rgb(152, 251, 152);
//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;
// const StyledTitleSecondary = styled.div`
//     color: ${props => props.theme.colors.primary.secondary};
//     font-size: 16px;
//     font-weight: 600;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;

export const StyledFooterDarkModeSwitch = styled.div`
    @media (max-width: 1130px) {
        display: flex;
        justify-content: center;
    }
`;

export default StartFooter;
