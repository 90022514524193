import React from "react";
import { Box, Card, CardContent, CardIcon, Separator, Spacer } from "react-neu";
import styled from "styled-components";
import StartSplit from "components/StartSplit";
import StartPage from "components/StartPage";
import { QualiopiLogo } from "components/Logo/QualiopiLogo";

const ForfaitContainer: React.FC = () => {
    return (
        <StartPage>
            <StyledTitle>Nos Services de formations en Permis de conduire et Code de la Route à Marseille</StyledTitle>
            {/* <StyledHeaderOne>
                Spécialisé dans la formation en conduite depuis plus de 10 ans, nous proposons des diverses formations au Permis de conduire et Code de la Route.
            </StyledHeaderOne> */}

            {/* <StyledHeaderOne>L'auto-école à Marseille 13001, pour obtenir votre votre Code de la Route et votre permis rapidement, et à bas prix.</StyledHeaderOne> */}

            {/* <StyledHeaderOne>Nous proposons des services de formations à la conduite de qualités, en réponda</StyledHeaderOne> */}
            {/* <StyledSectionIcon>⚖️</StyledSectionIcon> */}
            <Spacer size="sm" />
            {/* <CardContent>
                <StyledCardTitle>Heures de conduite à l'unité </StyledCardTitle>
                <StyledCardDescription> 38 €</StyledCardDescription>

                <Spacer size="sm" />
            </CardContent> */}

            <StartSplit>
                <Card>
                    {/* <Spacer size="md" /> */}
                    <CardIcon>🎉</CardIcon>
                    <StyledCardName> Forfait classique : Boîte manuelle </StyledCardName>

                    {/* <StyledCardDescription>Le meilleur prix à Marseille</StyledCardDescription>
                    <StyledCardTitle> Code et Permis : 960€</StyledCardTitle> */}
                    <CardContent>
                        {/* <StyledCardDescription>Le meilleur prix à Marseille</StyledCardDescription> */}
                        <StyledCardDescription> Tarif honoraire : 46€</StyledCardDescription>
                    </CardContent>

                    <CardContent>
                        <StyledCardTitle>Code et Permis de conduire</StyledCardTitle>
                        <Spacer size="sm" />
                        <StyledCardDescription> 960€ </StyledCardDescription>
                    </CardContent>
                    <CardContent>
                        <StyledCardTitle> Pack de 10 heures</StyledCardTitle>
                        <Spacer size="sm" />
                        <StyledCardDescription> 400€ </StyledCardDescription>
                    </CardContent>
                    <CardContent>
                        <StyledCardTitle> Formule de 20 heures</StyledCardTitle>
                        <Spacer size="sm" />
                        <StyledCardDescription> 699€ </StyledCardDescription>
                    </CardContent>

                    <CardContent>
                        <StyledCardTitle>Conduite accompagnée</StyledCardTitle>
                        <Spacer size="sm" />
                        <StyledCardDescription> 1200€ </StyledCardDescription>
                    </CardContent>

                    <Separator />
                </Card>

                <Card>
                    <Spacer size="md" />
                    <CardIcon>🤝</CardIcon>
                    <CardContent>
                        <StyledCardName> Forfait Boîte automatique </StyledCardName>
                        <StyledCardDescription> Tarif honoraire : 48€</StyledCardDescription>
                    </CardContent>

                    {/* <CardContent>
                        <StyledCardTitle>Code et Permis de conduire</StyledCardTitle>
                        <Spacer size="sm" />
                        <StyledCardDescription> 960€ </StyledCardDescription>
                    </CardContent> */}
                    <CardContent>
                        <StyledCardTitle> Pack de 10 heures</StyledCardTitle>
                        <Spacer size="sm" />
                        <StyledCardDescription> 480€ </StyledCardDescription>
                    </CardContent>
                    <CardContent>
                        <StyledCardTitle> Formule de 13 heures</StyledCardTitle>
                        <Spacer size="sm" />
                        <StyledCardDescription> 624€ </StyledCardDescription>
                    </CardContent>

                    <CardContent>
                        <StyledCardTitle>Conduite accompagnée</StyledCardTitle>
                        <Spacer size="sm" />
                        <StyledCardDescription> 960€ </StyledCardDescription>
                    </CardContent>
                    <CardContent>
                        <StyledCardTitle> Passerelle boîte auto à manuelle</StyledCardTitle>
                        <Spacer size="sm" />
                        <StyledCardDescription> 380€ </StyledCardDescription>
                    </CardContent>

                    <Separator />
                </Card>
            </StartSplit>

            <Box>
                <p>Formations éligibles au CPF</p>
                <QualiopiLogo></QualiopiLogo>
            </Box>

            {/* <StartSplit>
                <Card>
                    <Spacer size="md" />
                    <CardIcon>🤝</CardIcon>
                    <StyledCardName> Forfait Boîte automatique </StyledCardName>

                    <CardContent>
                        <StyledCardDescription> Tarif honoraire : 45€</StyledCardDescription>
                        <Spacer size="sm" />

                        <StyledCardTitle> Formule 13 heures : 585€</StyledCardTitle>
                        <StyledCardTitle> Pack 10 heures : 450€</StyledCardTitle>
                        <Spacer size="sm" />

                        <StyledCardTitle> Conduite accompagné 960€</StyledCardTitle>
                        <StyledCardTitle> Passerelle boîte auto à manuelle : 350€</StyledCardTitle>

                        <StyledList> Rendez vous pédagogique</StyledList>
                        <StyledList> Code de la route</StyledList>
                        <StyledList> 20 heures de conduite</StyledList>
                        <StyledList> Accès à la salle de code gratuite</StyledList>
                        <StyledList> Code de la route</StyledList>
                        <StyledList> 20 heures de conduite</StyledList>
                        <StyledList> Accès à la salle de code gratuite</StyledList>
                    </CardContent>
                    <Separator />
                    <StyledCardTitle> Formule 13 heures : 585€</StyledCardTitle>

                    <StyledCardTitle> 1000 €</StyledCardTitle>
                </Card>
            </StartSplit> */}

            {/* <Card> */}
            {/* <CardContent>
                <StyledCardTitle>Heures de conduite à l'unité </StyledCardTitle>
                <StyledCardDescription> 38 €</StyledCardDescription>

                <Spacer size="sm" />
            </CardContent> */}
            {/* </Card> */}

            {/* </Card> */}
        </StartPage>
    );
};

const StyledTitle = styled.title`
    color: rgb(152, 251, 152);
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    @media (max-width: 600px) {
        justify-content: center;
        font-size: 18px;
        font-weight: 100;
        width: auto;
    }
`;

const StyledHeaderOne = styled.h1`
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    @media (max-width: 600px) {
        justify-content: center;
        font-size: 16 px;
        font-weight: 100;
        width: auto;
    }
`;
// const StyledHeaderTwo = styled.h2`
//     font-size: 17px;
//     text-align: center;
//     @media (max-width: 600px) {
//         justify-content: center;
//         font-size: 15px;
//         width: auto;
//     }
// `;
// const StyledHeaderOne = styled.h1`
//     font-size: 20px;
//     font-weight: 100;
//     text-align: center;
//     @media (max-width: 600px) {
//         justify-content: center;
//         font-size: 13px;
//         font-weight: 100;
//         width: auto;
//     }
// `;

// const StyledSectionTitle = styled.div`
//     color: ${props => props.theme.colors.primary.main};
//     color: 	rgb(152,251,152);

//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;
// const StyledSectionTitle = styled.title`
//     color: rgb(152, 251, 152);
//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;

// const StyledSectionIcon = styled.div`
//     font-size: 64px;
//     text-align: center;
// `;
const StyledCardTitle = styled.div`
    font-size: 16px;
    min-height: 56px;
    text-align: center;
`;
const StyledList = styled.li`
    color: ${props => props.theme.colors.grey[400]};
    font-size: 16px;
    min-height: 56px;
    text-align: center;
`;

// const StyledSectionDescription = styled.h3`
//     color: ${props => props.theme.textColor};
//     font-size: 18px;
//     opacity: 0.66;
//     font-weight: 400;
//     text-align: center;
// `;

const StyledCardName = styled.div`
    color: ${props => props.theme.textColor};
    font-size: 24px;
    font-weight: 700;
    text-align: center;
`;

const StyledCardDescription = styled.div`
    color: ${props => props.theme.colors.grey[400]};
    font-size: 16px;
    min-height: 56px;
    text-align: center;
`;

export default ForfaitContainer;
