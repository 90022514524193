import React from "react";
import styled from "styled-components";

// import "./DrawerToggleButton.css";

interface ButtonDrawerInterface {

    handleClick:() => void;

}
const DrawerToggleButton: React.FC<ButtonDrawerInterface> = (props:ButtonDrawerInterface) => (
    <StyledButton className="toggle-button" onClick={props.handleClick}>
        <StyledButtonLine className="toggle-button__line" />
        <StyledButtonLine className="toggle-button__line" />
        <StyledButtonLine className="toggle-button__line" />
    </StyledButton>
);

const StyledButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
`;
const StyledButtonLine = styled.div`
    width: 30px;
    height: 2px;
    background: white;
    :focus {
        outline: none;
    }
`;

export default DrawerToggleButton;
