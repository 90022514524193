import React from "react";
import styled from "styled-components";

import StartFooter from "../StartFooter";
import { useLocation } from "react-router-dom";
// import TopBar from "components/TopBar";
import { Spacer } from "react-neu";

// const img = "/car_banner.jpg";

const StartPage: React.FC = ({ children }) => {
    const location = useLocation();

    return (
        <>
            <StyledPage>
                {/* <TopBar></TopBar> */}
                <Spacer size={"lg"}/>
                <StyledMain>{children}</StyledMain>
                {!location.pathname.includes("contact") && <StartFooter />}
            </StyledPage>
        </>
    );
};

const StyledPage = styled.main`
`;

// const StyledMain = styled.div`
//   align-items: center;
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;
//   min-height: calc(100vh - 215px);

//   padding: ${(props) => props.theme.spacing[6]}px 0;
// `;

const StyledMain = styled.body`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 215px);
    color: ${props => props.theme.textColor};
    padding: ${props => props.theme.spacing[6]}px 0;
`;

// border: 1px solid #000;
// width: 2000px;
// height: 2000px;

export default StartPage;
