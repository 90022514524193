import React from "react";
import { Spacer, Card } from "react-neu";
import styled, { keyframes } from "styled-components";
import { StyledTelRef, StyledLink } from "components/ActionButton";
// import { ButtonAction } from "components/ActionButton/ActionButton";

// import DrawerToggleButton from "./DrawerToggleButton";
// import StyledLink from "./StyledLink";

interface NavProps {
    onDismiss: () => void;
    mobileMenu: boolean;
    isOpen: boolean;
}

const Nav: React.FC<NavProps> = ({ onDismiss, mobileMenu, isOpen }) => {
    return (
        <>
            {isOpen && mobileMenu && (
                <StyledNav>
                    <StyledBackdrop onClick={onDismiss}></StyledBackdrop>
                    <Spacer size="md"></Spacer>

                    <StyledModal onClick={onDismiss}>
                        <Spacer size="lg"></Spacer>

                        <StyledCardButtonLink>
                            <StyledLink to="/services"> Nos formations</StyledLink>
                        </StyledCardButtonLink>
                        {/* <Spacer size="sm"></Spacer> */}

                        <StyledCardButtonLink>
                            <StyledLink to="/contact"> Contacter nous</StyledLink>
                        </StyledCardButtonLink>

                        {/* <Spacer size="md"></Spacer> */}

                        <StyledCardButton>
                            <StyledTelRef href="tel:0954165907"> ☎- +33 9 54 16 59 07 </StyledTelRef>
                        </StyledCardButton>

                        {/* <Spacer></Spacer> */}

                        <StyledCardButton>
                            <StyledTelRef href="tel:0778008275"> ☎- +33 7 78 00 82 75 </StyledTelRef>
                        </StyledCardButton>

                        {/* <Spacer size="md"></Spacer> */}

                        <StyledCardButton>
                            <>👻- bmconduite</>
                        </StyledCardButton>

                        <Spacer></Spacer>

                        <StyledCardMap>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2903.5119157518843!2d5.389327150543104!3d43.30354208288671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9c09e7c4dd647%3A0x78cba775502ac00a!2s92%20Rue%20Jean%20de%20Bernardy%2C%2013001%20Marseille%2C%20France!5e0!3m2!1sen!2snl!4v1643570955781!5m2!1sen!2snl"
                                width="100%"
                                height="100%"
                                title="Nous retrouver et commencer son Code de la Route et Permis de conduire ?"
                                // style="border:0;"
                                style={{ border: "0" }}
                                // allowFullScreen={true}
                                loading="lazy"
                            ></iframe>
                        </StyledCardMap>

                        <p> 92 Rue Jean de Bernardy </p>
                        <p> 13001 Marseille, France</p>
                    </StyledModal>
                </StyledNav>
            )}
        </>
    );
};

export const StyledCardMap = styled(Card)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: 44px;
    min-width: 44px;
    padding: 0;
    width: 450px;
    height: 100%;
    text-decoration: none;
    @media (max-width: 650px) {
        justify-content: center;
        width: 100%;
        height: 100%;
        width: auto;
    }
`;

// const StyledButtonLink = styled.button`
//     box-sizing: border-box;
//     border: 1px solid #d9252c;
//     background: #d9252c;

//     color: #fff;
//     border-radius: 0.33em;
//     margin: 5px;

//     max-width: 420px;
//     @media (max-width: 650px) {
//         height: 100%;
//     }
// `;

// const StyledButtonLink = styled.button`
//     padding: 1.3em 1.5em 1.2em;
//     box-sizing: border-box;
//     border: 1px solid #d9252c;
//     background: #d9252c;

//     color: #fff;
//     border-radius: 0.33em;
//     font-size: 1.5rem;
//     line-height: 1.2em;
//     text-indent: 0.12em;
//     letter-spacing: 0.12em;
//     max-width: 420px;
//     @media (max-width: 650px) {
//         height: 100%;
//     }
// `;
const StyledCardButton = styled.div`
    color: ${props => props.theme.textColor};
    width: 100%;
    margin: 5px;
    padding: 1px;
    text-align: left;
`;

const StyledCardButtonLink = styled.div`
    color: ${props => props.theme.textColor};
    margin: 5px;
    padding: 1px;
    text-align: left;
    box-sizing: border-box;
    border: 1px solid darkcyan;
    background: darkcyan;
    color: #fff;
    border-radius: 0.33em;
    font-size: 1.5rem;
    &:hover {
        color: ${(props) => props.theme.colors.grey[600]};
    }
    @media (max-width: 650px) {

    }
`;

// const StyledModal = styled.div`
//     align-items: center;
//     background-color: #d9252c;
//     width: 80%;
//     border: 1px solid black;
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     position: absolute;
//     width: 70%;
//     background: rgba(0, 0, 0, 0.5);
// `;

const StyledModal = styled.div`
    align-items: center;
    width: 40%;
    border: 1px solid black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    box-shadow: 1px 1px 3px 0px #3c37376e !important;
    border: 1px solid #574747;
    color: hsl(229deg 100% 100% / 100%);
    heigth: 100%;
    background: ${props => props.theme.colors.grey[700]};
    color: ${props => props.theme.colors.grey[500]};
    background-size: 80px 50px;
    @media (max-width: 770px) {
        align-items: center;
        width: 65%;
        heigth: 100%;
        border: 1px solid black;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`;
// radial-gradient(circle at center top, rgb(32, 34, 45), rgb(20, 22, 31)) 0% 0% / cover fixed

// position: absolute;
// width: 70%;
// background: rgb(45, 32, 36) !important;
// color: #fff !important;
// color : hsl(229deg 100% 100% / 100%);
// box-shadow: 1px 1px 3px 0px #3c37376e !important;
// border: 1px solid #574747;

// background: rgb(45, 32, 36) !important;
// color: #fff !important;
// box-shadow: 1px 1px 3px 0px #3c37376e !important;
// border: 1px solid #574747;
// radial-gradient(circle at center top, rgb(32, 34, 45), rgb(20, 22, 31)) 0% 0% / cover fixed

// background-size: 80px 50px;
// background-image: linear-gradient(
//   115deg,
//   rgb(255 255 255 / 0%) 10%,
//   #ffffff00 35%,
//   #ffffff00 50%,
//   rgb(0 0 0 / 0.15) 50%,
//   rgb(0 0 0 / 0.15) 75%,
//   #ffffff00 75%,
//   #ffffff00
// );

// const StyledModal = styled.div`
//     align-items: center;
//     radial-gradient(circle at center top, rgb(32, 34, 45), rgb(20, 22, 31)) 0% 0% / cover fixed
//     width: 60%;
//     border: 1px solid black;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     position: absolute;
//     width: 60%;
//     background: rgb(45, 32, 36) !important;
//     color: #fff !important;
//     box-shadow: 1px 1px 3px 0px #3c37376e !important;
//     border: 1px solid #574747;

//     @media (max-width: 770px) {
//     align-items: center;
//     radial-gradient(circle at center top, rgb(32, 34, 45), rgb(20, 22, 31)) 0% 0% / cover fixed
//     width: 80%;
//     heigth: 100%;
//     border: 1px solid black;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     position: absolute;
//     width: 70%;
//     background: rgb(45, 32, 36) !important;
//     color: #fff !important;
//     color : hsl(229deg 100% 100% / 100%);
//     box-shadow: 1px 1px 3px 0px #3c37376e !important;
//     border: 1px solid #574747;
// }

// `;
// background-color: #858585;

// background: rgba(255, 24, 24, 0.9);

// background: #d9252c;

// const slideIn = keyframes`
//   0% {
//     transform: translateX(-100%)
//   }
//   100% {
//     transform: translateX(0);
//   }
// `;

// const StyledNav = styled.div`
//     align-items: center;
//     display: flex;
//     @media (max-width: 770px) {
//         animation: ${slideIn} 0.3s forwards ease-out;
//         flex-direction: column;
//         position: fixed;
//         background: rgb(243, 237, 239);
//         justify-content: flex-start;
//         padding-top: 50px;
//         width: 60%;
//         top: 0;
//         right: 0;
//         bottom: 0;
//         left: 0;
//         z-index: 1000;
//     }
// `;

const slideIn = keyframes`
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0);
  }
`;

const StyledNav = styled.div`
    align-items: center;
    display: flex;
    padding-top: 15px;
    width: 60%;

    @media (max-width: 770px) {
        animation: ${slideIn} 0.3s forwards ease-out;
        flex-direction: column;
        position: fixed;
        justify-content: flex-start;
        padding-top: 50px;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
    }
`;

// background: rgb(143, 207, 200);

// background-color: ${props => props.theme.colors.black};

// const StyledBackdrop = styled.div`
//     opacity: 0.75;
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
// `;
const StyledBackdrop = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export default Nav;
