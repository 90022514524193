import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

// import { Container, Spacer } from "react-neu";

// import Logo from "components/Logo";
// // import Logo from "components/IconLogo";
// // import DarkModeSwitch from "../DarkModeSwitch";
// import fruitsBack from "assets/galery/fruit.png";
// import { Link } from "react-router-dom";

// import SideDrawer from "./components/SideDrawer";
// import DrawerToggleButton from "./components/DrawerToggleButton";

interface TopBarProps {}

const ActionButton: React.FC<TopBarProps> = ({ children }) => {
    // const [isOpenDrawer, setOpenDrawer] = React.useState(false);
    // // const [isMobile, setIsMobile] = React.useState(false);

    // let isMobile = true;
    // if (typeof window != "undefined") {
    //     if (window.innerWidth > 500) {
    //         isMobile = false;
    //     } else {
    //         isMobile = true;
    //     }
    // }

    // const handleClickOpen = () => {
    //     setOpenDrawer(!isOpenDrawer);
    // };

    // let sideDrawer;

    // if (isOpenDrawer) {
    //     sideDrawer = <SideDrawer handleClick={handleClickOpen} />;
    // }
    return <StyledActionButton>{children}</StyledActionButton>;
};

export const StyledActionButton = styled.button`
    width: 100%;
    height: 50%;
    text-align: left;
    justify-content: center;
    color: ${props => props.theme.colors.grey[700]};
    background: ${props => props.theme.colors.grey[400]};

    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border-radius: 9px;
    @media (max-width: 650px) {
        text-align: center;
        width: 100%;
    }
`;

// const StyledActionButton = styled.button`
//     width: 100%;
//     height: 100%;
//     text-align: center;
//     justify-content: center;
//     font-size: 1em;
//     margin: 1em;
//     padding: 0.25em 1em;
//     border-radius: 9px;
//     background-color: #d9252c;
//     @media (max-width: 650px) {
//         text-align: center;
//         width: 100%;
//         width: 50px;
//     }
// `;

// const StyledActionButton = styled.button`
//     display: flex;
//     width: 70px;
//     height: 100%;

//     background-color: #333;
//     background-image: url(${fruitsBack});
//     @media (max-width: 650px) {
//         justify-content: center;
//         width: 100%;
//         width:50px;
//     }
// `;

export const StyledTelRef = styled.a.attrs({
    // href: "tel:0952552068",
})`
    font-size: 18px;
    color: ${props => props.theme.textColor};

    justify-content: center;
    align-items: center;
    border-radius: 3%;
    heigth: 100%;
    text-align: center;
    @media (max-width: 700px) {
        justify-content: center;
    }
`;



// export const ButtonAction = styled.button`
//     vertical-align: middle;
//     display: inline-block;
//     width: 100%;
//     margin: 7px;
//     padding: 1.3em 1.5em 1.2em;
//     box-sizing: border-box;
//     border: 1px solid #d9252c;
//     background: #d9252c;
//     color: #fff;
//     border-radius: 0.33em;
//     font-size: 1.5rem;
//     line-height: 1.2em;
//     text-decoration: none;
//     text-transform: uppercase;
//     text-indent: 0.12em;
//     letter-spacing: 0.12em;
//     @media (max-width: 650px) {
//         width: 300px;
//         height: 100%;
//     }
// `;

// export const ButtonAction = styled.button`
//     vertical-align: middle;
//     display: inline-block;
//     width: 100%;
//     margin: 7px;
//     padding: 1.3em 1.5em 1.2em;
//     box-sizing: border-box;
//     border: 1px solid #d9252c;
//     background: #d9252c;
//     color: #fff;
//     border-radius: 0.33em;
//     font-size: 1.5rem;
//     line-height: 1.2em;
//     text-decoration: none;
//     text-transform: uppercase;
//     text-indent: 0.12em;
//     letter-spacing: 0.12em;
//     max-width:420px;
//     @media (max-width: 650px) {
//         width: 300px;
//         height: 100%;
//         max-width:320px;

//     }
// `;

export const StyledLink = styled(Link)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 1px;
    min-height: 17px;
    min-width: 44px;
    padding: 0;
    color: rgb(152, 251, 152);
    @media (max-width: 650px) {
        justify-content: center;
    }
`;




export const ButtonAction = styled.button`
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    margin: 17px;
    text-align:center;
    padding: 1.3em 1.5em 1.2em;
    box-sizing: border-box;
    border: 1px solid darkcyan;
    background: darkcyan;
    color: #fff;
    border-radius: 0.33em;
    font-size: 1.5rem;
    &:hover {
        color: ${(props) => props.theme.colors.grey[600]};
    }
    @media (max-width: 650px) {
        width: 300px;
        height: 100%;
        max-width:350px;
        margin: 11px;
        padding: 1.3em 1.5em 1.2em;

    }
`;


export default ActionButton;
