import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const IconLogo: React.FC = () => {
  return (
    <StyledLogo to="/">
      <StyledEmoji>🚗</StyledEmoji>
      <StyledText>BM Conduite Marseille</StyledText>
    </StyledLogo>
  );
};

const StyledLogo = styled(Link)`
  align-items: center;
  display: column;
  justify-content: center;
  margin: 0;
  min-height: 44px;
  min-width: 44px;
  padding: 0;
  text-decoration: none;
`;

const StyledEmoji = styled.span.attrs({
  role: "img",
  alt:"BM conduite Marseille - Permis de conduire et code de la route  via véhicule écologique"
})`
  font-size: 24px;
  color: ${(props) => props.theme.textColor};
`;

const StyledText = styled.span`
  color: ${(props) => props.theme.textColor};
  font-size: 18px;
  font-weight: 500;
  widht:110px

  margin-left: ${(props) => props.theme.spacing[2]}px;
  @media (max-width: 550px) {
    display: none;
  }
`;

export default IconLogo;
