import React, { useMemo } from "react";
import { createTheme, ThemeProvider } from "react-neu";
import useLocalStorage from "hooks/useLocalStorage";
import { Route, Switch, HashRouter } from "react-router-dom";
import Start from "views/Start";
import Contact from "views/Contact/Contact";
import TopBar from "components/TopBar";
import ScrollToTop from "components/ScrollToTop";
import HeaderSEO from "SEO";
import ForfaitContainer from "views/Start/components/ForfaitContainer";
import Faq from "views/Faq";

function App() {
    return (
        <HashRouter>
            <Providers>
                <HeaderSEO />
                <ScrollToTop></ScrollToTop>

                <TopBar />
                <Switch>
                    {/* <HeaderSEO /> */}

                    <Route exact path="/">
                        <Start />
                    </Route>

                    <Route exact path="/contact">
                        <Contact />
                    </Route>

                    <Route exact path="/services">
                        <ForfaitContainer />
                    </Route>

                    <Route exact path="/faq">
                        <Faq/>
                    </Route>
                </Switch>
            </Providers>
        </HashRouter>
    );
}

const Providers: React.FC = ({ children }) => {
    const [darkModeSetting] = useLocalStorage("darkMode", true);
    const { dark: darkTheme, light: lightTheme } = useMemo(() => {
        return createTheme({
            baseColor: { h: 240, s: 100, l: 41 },
            baseColorDark: { h: 229, s: 89, l: 50 },
            borderRadius: 28,
        });
    }, []);
    return (
        <ThemeProvider darkModeEnabled={darkModeSetting} darkTheme={darkTheme} lightTheme={lightTheme}>
            {children}
        </ThemeProvider>
    );
};

export default App;
