import { Box } from "react-neu";

interface IQualiopiLogo {
    width?: string;
    height?: string;
    paddingVertical?:number
}
export const QualiopiLogo = ({ width, height, paddingVertical }: IQualiopiLogo) => {
    return (
        <Box width={width ?? "125px"} height={height ?? "125px"} paddingVertical={paddingVertical ?? 3}>
            <img width={"100%"} height={"100%"} alt="Logo Qualiopi" src="/LogoQualiopi.png"></img>
        </Box>
    );
};
