import React from "react";
import { Card, Spacer } from "react-neu";
import styled from "styled-components";
import StartPage from "components/StartPage";
import PageIcon from "components/PageIcon";
import { ButtonAction, StyledLink } from "components/ActionButton";
// import ForfaitContainer from "./components/ForfaitContainer";
// import HeaderSEO from "SEO";
// import HeaderSEO from "SEO";

const carSrcImg = "/car_green.jpg";

const Start: React.FC = () => {
    return (
        <StartPage>
            {/* <HeaderSEO/> */}
            <StyledTitle>Bienvenue à Auto-école Marseille BM conduite - Formations au Code de la Route et Permis de Conduite </StyledTitle>
            {/* <StyledTitle>Bienvenue à BM conduite Marseille - Spécialiste de la formation du code & permis </StyledTitle> */}
            <StyledHeaderOne>L'auto-école BM Conduite à Marseille 13001, pour obtenir votre Code de la Route et votre permis rapidement.</StyledHeaderOne>

            {/* <StyledCardDescription> */}
            <StyledTextPrimary>
                <b>Besoins de plus amples informations ?</b>
            </StyledTextPrimary>
            <StyledButtonContainer>
                <ButtonAction>
                    <StyledLink to="/contact">Contacter nous</StyledLink>
                </ButtonAction>
                {/* <StyledTextPrimary>
                    <b>Envie de prendre votre mobilité ? Venez prendre votre mobilité</b>
                </StyledTextPrimary> */}
                <StyledTextPrimary>
                    <b>Venez prendre votre mobilité</b>
                </StyledTextPrimary>
                <ButtonAction>
                    <StyledLink to="/services"> Nos formations</StyledLink>
                </ButtonAction> 
                {/* 
                <StyledLink to="/contact">
                    <ActionButton>Contact</ActionButton>
                </StyledLink>
                <StyledLink to="/services"> Services</StyledLink> */}
            </StyledButtonContainer>
            {/* </StyledCardDescription> */}

            <StyledHeaderTwo>
                BM conduite Marseille est une auto école qui compte 3 moniteurs/monitrices, 4 voitures et totalise 10 ans d'expériences dans l'apprentissage à la conduite.
                <p>
                    <b>
                        Votre <i> Code de la Route </i>et votre <i> Permis de Conduire </i> au plus vite
                    </b>
                    , avec nos formateurs passionnés et pédagogues, et ceci au meilleur prix.
                </p>
            </StyledHeaderTwo>

            <Spacer size="sm"></Spacer>

            <article>
                <PageIcon
                    icon={<StyledImage />}
                    title="Obtenez votre Code de la Route et votre permis de conduire rapidement.🎯"
                    subtitle="Marseille Auto Ecole BM Conduite s'engage à une obligation de résultat et a un taux de réussite. Nous pensons à vos économies : d'argent, de temps, tout en étant bienveillant envers l'écologique !"
                />

                {/* <StyledTextPrimary>
                    BM conduite Marseille est une auto école qui compte 3 moniteurs/ monitrices, 4 voitures et totalise 10 ans d'expérience dans l'apprentissage à la conduite.
                </StyledTextPrimary> */}

                {/* <h3>Marseille Auto Ecole BM Conduite s'inscrit dans une démarche de qualité de l'enseignement, et de transparence des prix.</h3> */}
                <StyledTextPrimary>
                    Votre #autoecole vous offre 2 mois d'accès a la salle de code gratuitement, avec un accueil chaleureux et des conseils avisés pour vous accompagner.
                </StyledTextPrimary>
                <StyledTextPrimary>
                    Nous mettons tout en place, de la voiture au moniteurs, afin que vous obtenez votre Code de la Route et votre Permis de Conduire.
                </StyledTextPrimary>
                {/* <StyledTextPrimary>#codedelaroute et #permisdeconduire disponible sur Marseille</StyledTextPrimary> */}
                <StyledTextPrimary>
                    Notre équipe, moniteurs et monitrices mettent à votre service leurs expertisent afin de vous présenter au permis de conduire dans les meilleurs conditions.
                </StyledTextPrimary>
            </article>
        </StartPage>
    );
};

// const StyledText = styled.p`
//     color: ${props => props.theme.textColor};
//     font-size: 18px;
//     opacity: 0.66;
//     font-weight: 400;
//     text-align: center;
//     @media (max-width: 400px) {
//         justify-content: center;
//         font-size: 14px;
//         font-weight: 100;
//         width: auto;
//     }
// `;

// const StyledButtonContainer = styled.div`
//     justify-content: center;
//     text-align: center;
//     display: inline-flex;
//     align-items: center;
// `;

const StyledButtonContainer = styled.div``;

const StyledTitle = styled.title`
    color: rgb(152, 251, 152);
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    @media (max-width: 600px) {
        justify-content: center;
        font-size: 24px;
        font-weight: 100;
        width: auto;
    }
`;

const StyledHeaderOne = styled.h1`
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    @media (max-width: 600px) {
        justify-content: center;
        font-size: 16 px;
        font-weight: 100;
        width: auto;
    }
`;

const StyledHeaderTwo = styled.h2`
    font-size: 17px;
    text-align: center;
    @media (max-width: 600px) {
        justify-content: center;
        font-size: 15px;
        width: auto;
    }
`;
const StyledTextPrimary = styled.p`
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    @media (max-width: 400px) {
        justify-content: center;
        font-size: 14px;
        font-weight: 100;
        width: auto;
    }
`;

// const StyledContainerPresentation = styled(Container)`
//     color: rgb(152, 251, 152);
//     font-size: 25px;
//     opacity: 0.66;
//     font-weight: 400;
//     text-align: center;
//     @media (max-width: 600px) {
//         display: none;
//         justify-content: center;
//         font-size: 14px;
//         font-weight: 100;
//         width: auto;
//     }
// `;

// const StyledTitle = styled.div`
//     font-size: 24px;
//     font-weight: 700;
//     text-align: center;
//     @media (max-width: 400px) {
//         justify-content: center;
//         font-size: 14px;
//         font-weight: 100;
//         width: auto;
//     }
// `;

// const StyledList = styled.li`
//     color: ${props => props.theme.colors.grey[400]};
//     font-size: 16px;
//     min-height: 56px;
//     text-align: center;
// `;

// const StyledCardMap = styled.

export const StyledCardDescription = styled(Card)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: 44px;
    min-width: 44px;
    padding: 0;
    width: 300px;
    text-decoration: none;
    @media (max-width: 400px) {
        justify-content: center;
        width: auto;
    }
`;

// const StyledCardName = styled.div`
//     color: ${props => props.theme.textColor};
//     font-size: 24px;
//     font-weight: 700;
//     text-align: center;
// `;

const StyledImage = styled.img.attrs({
    src: `${carSrcImg}`,
    alt: "BM conduite Marseille - Permis de conduire et code de la route  via véhicule écologique",
})`
    color: ${props => props.theme.textColor};
    font-size: 18px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    text-align: center;
    @media (max-width: 700px) {
        justify-content: center;
        width: 300px;
        height: 200px;
        width: auto;
    }
`;

// const StyledSectionDescription = styled.h3`
//     color: ${props => props.theme.textColor};
//     font-size: 18px;
//     opacity: 0.66;
//     font-weight: 400;
//     justify-content: center;
//     align-items: center;
//     border-radius: 10%;
//     text-align: center;
//     @media (max-width: 700px) {
//         justify-content: center;
//         width: 300px;
//         height: 200px;
//         width: auto;
//     }
// `;

// const StyledImage = styled.img.attrs({
//     src: `${carSrcImg}`,
//     alt:""
//   })`
//   width: 50px;
//   height: 30px;
//   color: ${props => props.theme.textColor};
//   font-size: 18px;
//   opacity: 0.66;
//   font-weight: 400;
//   justify-content:center;
//   align-items: center;
//   border-radius: 50%;
//   text-align: center;
//   `;

// const StyledImage = styled.image`
//     color: ${props => props.theme.textColor};
//     font-size: 18px;
//     opacity: 0.66;
//     font-weight: 400;
//     justify-content:center;
//     align-items: center;
//     border-radius: 50%;
//     text-align: center;
// `;

// const StyledSectionTitle = styled.div`
//     color: ${props => props.theme.colors.primary.main};
//     color:#98FB98
//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;

// const StyledImage = styled.img.attrs({
//     src: `${carSrcImg}`,
//     alt:""
//   })`
//   width: 50px;
//   height: 30px;
//   color: ${props => props.theme.textColor};
//   font-size: 18px;
//   opacity: 0.66;
//   font-weight: 400;
//   justify-content:center;
//   align-items: center;
//   border-radius: 50%;
//   text-align: center;
//   `;

// const StyledImage = styled.image`
//     color: ${props => props.theme.textColor};
//     font-size: 18px;
//     opacity: 0.66;
//     font-weight: 400;
//     justify-content:center;
//     align-items: center;
//     border-radius: 50%;
//     text-align: center;
// `;

// const StyledSectionTitle = styled.div`
//     color: ${props => props.theme.colors.primary.main};
//     color:#98FB98
//     font-size: 36px;
//     font-weight: 700;
//     margin: 0;
//     padding: 0;
//     text-align: center;
// `;

export default Start;
