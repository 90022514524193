import { Helmet } from "react-helmet";
const HeaderSEO = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                {/* <base target="_blank" href="https://bmconduite-autoecole.com/" /> */}
                <link rel="canonical" href="https://bmconduite-autoecole.com/" />
                <meta name="robots" content="index, follow" />
                <meta name="google-site-verification" content="X7_i_fQK7FvaYS-Ll0dOEWbh4qYQWWGSDwNsGla5ibw" />
                <link rel="canonical" href="https://bmconduite-autoecole.com/" />
                <title>Auto-école Marseille BM Conduite - Code et Permis de conduire</title>

                <meta
                    name="description"
                    content="Votre auto-école à Marseille pour obtenir votre permis de conduire à 699€ rapidement. Auto-école Marseille BM conduite pense à vos économies :  d'argent, de temps, tout en étant bienveillant envers l'écologique !"
                />
                <meta
                    name="keywords"
                    content="auto-école,auto ecole marseille, auto école marseille BM Conduite, BM Conduite, Marseille, Permis, Code, Permis accéléré, Code, Permis de conduire, Code de la Route, Formations, Moniteurs"
                />

                {/* <!-- Open Graph --> */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://bmconduite-autoecole.com/" />
                <meta property="og:title" content="Auto-école Marseille BM Conduite - Code et Permis de conduire" />
                <meta
                    name="og:description"
                    content="BM conduite est une auto école qui compte 3 moniteurs/ monitrices, 4 voitures et totalise 10 ans d'expérience dans l'apprentissage a la conduite.
                    92 Rue Jean de Bernardy, 13001 Marseille"
                />
                <meta property="og:site_name" content="Marseille Auto Ecole BM Conduite - Code et Permis de conduire" />
                <meta property="og:image" content="https://bmconduite-autoecole.com/logo.jpg" />
                <link rel="icon" href="%PUBLIC_URL%//favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />

                {/* <!-- Socials meta SEO --> */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@BMCONDUITEMARS" />
                <meta name="twitter:title" content="Auto Ecole BM Conduite Marseille" />
                <meta
                    name="twitter:description"
                    content="Marseille Auto Ecole BM conduite met a votre service son expérience, son expertise & savoir faire dans le secteur de l'auto école."
                />
                <meta name="twitter:image" content="%PUBLIC_URL%//favicon.ico" />

                {/* <!-- Images --> */}
                <link rel="apple-touch-icon" sizes="180x180" href="%PUBLIC_URL%//apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="%PUBLIC_URL%//favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="%PUBLIC_URL%//favicon-16x16.png" />
                <link rel="manifest" href=" %PUBLIC_URL%//manifest.json" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%//favicon.ico" />

                <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800;900&display=swap" rel="stylesheet" />
            </Helmet>
        </>
    );
};

export default HeaderSEO;
